<template>
    <v-container class="">
        <v-row class="pb-0">
            <v-col cols="12" class="pb-0">
                <h1 class="text-h5 mb-0 font-weight-bold">{{ $t('ร้านค้าไฮไลต์ของ LnwShop') }}</h1>
            </v-col>
        </v-row>
        <template v-if="highlightShops != null && highlightShops.length">
            <v-row v-for="year in years"
                   :key="year">
                <v-col :key="year"
                       cols="12"
                       v-if="highlightShops.some(shop => shop.year === year)">
                    <h3 class="year-title">{{ year }}</h3>
                </v-col>
                <v-col v-for="shop in highlightShops.filter(shop => shop.year === year)"
                       :key="shop.shop_id"
                       cols="12"
                       xl="4"
                       md="6"
                       :class="isDesktop?'mb-12':'mb-6'"
                       class="d-flex align-stretch p-0">
                    <v-card tile
                            class="shop-card text-decoration-none p-0 rounded-lg"
                            elevation="0"
                            link
                            :to="'/shop/' + shop.webname">
                        <v-card-title class="text-body-2 pb-0">
                            <v-img class="rounded-lg"
                                   style="outline: 1px solid #9993;"
                                   :src="shop.avatar"
                                   aspect-ratio="1.4159292"></v-img>
                        </v-card-title>
                        <div class="just_flex">
                            <div>
                                <v-card-subtitle class="text-success text-uppercase text-caption font-weight-bold pb-0"
                                                 style="font-size: 0.6em !important;">
                                    {{ `${new Date(shop.year, shop.month - 1).toLocaleString('en-US', { month: 'long' })} ${shop.year}` }}
                                </v-card-subtitle>
                                <div class="">
                                    <v-card-title class="pt-0 pb-0">
                                        <div class="line-clamp-1"
                                             style="font-size: 0.8em;">{{ shop.shopname }}
                                        </div>
                                    </v-card-title>
                                </div>
                            </div>
                            <v-btn @click.stop=""
                                   :href="shop.url"
                                   small
                                   elevation="0"
                                   class="primary--text rounded-xxl mr-4 text-caption font-weight-bold">SHOP
                            </v-btn>
                        </div>
                        <v-card-text class="pt-0">
                            <div class="line-clamp-2"
                                 style="font-size: 0.9em;">{{ shop.description }}
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </template>
        <template v-else>
            <v-row v-for="year in years"
                   :key="year">
                <v-col v-for="i in 3"
                       :key="i"
                       cols="12"
                       xl="4"
                       md="6"
                       :class="isDesktop?'mb-12':'mb-6'"
                       class="p-0">
                    <v-skeleton-loader style="padding: 16px;"
                                       type="image, article"></v-skeleton-loader>
                </v-col>
            </v-row>
        </template>
    </v-container>
</template>
<script>
import {mapActions, mapState} from "vuex";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";

export default {
    name: "HighlightShop",
    mixins: [
        MixinsHelper,
    ],
    data(){
        return {
            selectedYear: new Date().getFullYear(),
            currentYear: new Date().getFullYear(),
            startYear: 2021,
        };
    },
    computed: {
        ...mapState({
            highlightShops: state => state.user.highlightShops,
        }),
        years(){
            let years = [];
            for(let y = this.currentYear; y >= this.startYear; y--) {
                years.push(y);
            }
            return years;
        },
        filteredShops(){
            if(!this.selectedYear){
                return this.highlightShops;
            }
            return this.highlightShops.filter(shop => parseInt(shop.year) === parseInt(this.selectedYear));
        },
    },
    methods: {
        ...mapActions({
            fetchHighlightShops: 'user/fetchHighlightShops',
        }),
        selectYear(year){
            this.selectedYear = year;
        },
    },
    mounted(){
        if(this.highlightShops == null){
            this.fetchHighlightShops();
        }
    }
};
</script>