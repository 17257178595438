<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <h1 class="text-h5 font-weight-bold mb-4">{{ $t('ร้านค้าโปรดของฉัน') }}
                    <v-chip small v-if="favShops" class="ml-1">
                        {{ favShops.length ? favShops.length : '0' }}
                    </v-chip>
                </h1>
            </v-col>
            <template v-if="favShops != null && favShops.length">
            <v-col v-for="shop in favShops"
                   :key="shop.shop_id"
                   cols="12"
                   xl="3"
                   md="4"
                   sm="6"
                   :class="isDesktop?'mb-12':'mb-6'"
                   class="d-flex align-stretch p-0">
                <ShopCard :shop="shop"></ShopCard>
            </v-col>
            </template>
            <template v-else-if="favShops != null">
                <v-col cols="12" class="text-center">
                    <v-card class="pa-10 box-border shop-card text-decoration-none overflow-hidden  p-0 position-relative"
                            elevation="24">
                        <v-card-text :class="isDesktop?'rounded':'rounded-lg'" class="overflow-hidden">
                            {{ $t('คุณสามารถบันทึกร้านเป็นร้านโปรดได้ด้วยการคลิก') }}
                                <v-icon color="primary">mdi-heart-plus-outline</v-icon>
                                {{ $t('ที่หน้าร้านค้าที่คุณต้องการ') }}
                            <div>
                                <v-btn :to="'highlight'"
                                       color="primary"
                                       class="mt-4">{{ $t('ร้านค้าไฮไลต์') }}
                                </v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </template>
            <template v-else>
                <v-col
                        v-for="i in (6)" :key="i"
                        cols="12"
                        xl="3"
                        md="4"
                        class="p-0"
                        :class="isDesktop?'mb-12':'mb-6'"
                >
                    <v-skeleton-loader
                            style="padding: 16px;"
                            type="card, list-item-two-line"
                    ></v-skeleton-loader>
                </v-col>
            </template>
        </v-row>
    </v-container>
</template>
<script>
import {mapState} from "vuex";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";
import ShopCard from "@/components/App/components/ShopCard";

export default {
    name: "MyFavShops",
    mixins: [
        MixinsHelper,
    ],
    components: {
        ShopCard
    },
    data(){
        return {};
    },
    methods: {
    },
    computed: {
        ...mapState({
            favShops: state => state.user.favShops,
        })
    },
};
</script>