<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card class="pa-4" elevation="24">
                    <v-card-title>
                        <h3>สถานะการแจ้งเตือน</h3>
                    </v-card-title>
                    <v-card-text>
                        <p v-if="isSubscribed">
                            <v-icon color="success" class="mr-2">mdi-check-bold</v-icon>คุณได้สมัครรับการแจ้งเตือนแล้ว
                        </p>
                        <p v-else>คุณยังไม่ได้สมัครรับการแจ้งเตือน</p>
                        <v-divider class="mb-6"></v-divider>
                        <v-chip v-for="token in web_push_tokens" class="py-1 px-3 mr-2 mb-2" :key="token.device_token_id">
                            Device ID: {{ token.device_token_id }} ({{ token._created_time }})
                            <a v-if="false" class="red" @click="revoke(token)">ลบ</a>
                        </v-chip>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn v-if="!isSubscribed" class="px-6" large color="primary" @click="registerWebPushNotification" :disabled="!isSupportSW">
                            สมัครรับการแจ้งเตือน
                        </v-btn>
                        <v-btn v-else color="primary" class="px-6" large @click="unsubscribeWebPushNotification" :disabled="!isSupportSW">
                            ยกเลิกการแจ้งเตือน
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <!-- การแจ้งเตือนผ่านเว็บไซต์ Add to Home Screen (เพื่อให้เป็น PWA) -->
        <v-row class="mt-6">
            <v-col cols="12">
                <v-card class="pa-4" elevation="24">
                    <v-card-title>
                        <h3>ขั้นตอนการ Add to Home Screen</h3>
                    </v-card-title>
                    <v-card-text>
                        <p>เพิ่มทางลัดสำหรับเข้า Lnw.me ในลักษณะที่เหมือนเป็น App ซึ่งช่วยเพิ่มความสะดวกในการใช้งานและทำงานได้อย่างรวดเร็ว</p>
                        <v-divider class="my-4"></v-divider>
                        <!-- A. Mobile -->
                        <template v-if="activeDevice.device == 'mobile'" >
                            <h5 id="mobile">Mobile</h5>
                            <v-list>
                                <!-- iOS -->
                                <v-list-item v-if="activeDevice.os == 'ios'">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <strong>iOS</strong>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <v-list>
                                                <v-list-item>
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            Safari / Chrome
                                                        </v-list-item-title>
                                                        <v-list-item-subtitle>
                                                            1. กดปุ่ม Share
                                                            <v-icon color="primary"
                                                                    small
                                                                    class="ml-1">mdi-export-variant
                                                            </v-icon>
                                                            <br> 2. เลือก Add to Home Screen
                                                            <v-icon color="primary"
                                                                    small>mdi-plus-box-outline
                                                            </v-icon>
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <!-- Android -->
                                <v-list-item v-if="activeDevice.device == 'android'">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <strong>Android</strong>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <v-list>
                                                <v-list-item>
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            Chrome
                                                        </v-list-item-title>
                                                        <v-list-item-subtitle>
                                                            1. กดปุ่มเมนู
                                                            <v-icon small
                                                                    color="primary"
                                                                    class="ml-1">mdi-dots-vertical
                                                            </v-icon>
                                                            <br> 2. เลือก Add to Home screen
                                                            <v-icon small
                                                                    color="primary"
                                                                    class="ml-1">mdi-cellphone-arrow-down-variant
                                                            </v-icon>
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                            <v-divider class="my-4"></v-divider>
                        </template>
                        <!-- B. Desktop -->
                        <template v-else>
                            <h5 id="desktop">Desktop</h5>
                            <v-list>
                                <!-- macOS -->
                                <v-list-item v-if="activeDevice.os == 'macos'">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <strong>macOS</strong>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <v-list>
                                                <v-list-item>
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            Chrome
                                                        </v-list-item-title>
                                                        <v-list-item-subtitle>
                                                            1. คลิกที่ปุ่มเมนู <v-icon color="primary" small class="ml-1">mdi-dots-vertical</v-icon><br>
                                                            2. เลือก "Cast, Save, Share"<br>
                                                            3. เลือก "Install Lnw.me"
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                                <v-list-item>
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            Safari
                                                        </v-list-item-title>
                                                        <v-list-item-subtitle>
                                                            1. กดปุ่ม Share <v-icon color="primary" small class="ml-1">mdi-export-variant</v-icon>
                                                            <br>
                                                            2. เลือก Add to Dock
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <!-- Windows -->
                                <v-list-item v-if="activeDevice.os == 'windows'">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <strong>Windows</strong>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            <v-list>
                                                <v-list-item>
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            Chrome
                                                        </v-list-item-title>
                                                        <v-list-item-subtitle>
                                                            1. คลิกที่ปุ่มเมนู <v-icon color="primary" small class="ml-1">mdi-dots-vertical</v-icon><br>
                                                            2. เลือก "Cast, Save, Share"<br>
                                                            3. เลือก "Install Lnw.me"
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </template>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Axios from "@/libraries/Axios";
import ApiUrl from "@/libraries/ApiUrl";
import {mapActions, mapGetters, mapState} from "vuex";

export default {
    name: "NotiPage",
    data(){
        return {
            permissionStatus: "",
            subscriptionStatus: null,
            activeDevice : {
                os: '',
                device: 'desktop',
                browser: '',
            }
        };
    },
    computed: {
        ...mapGetters({
            cpFlagIsLogin: 'user/isLogin',
        }),
        ...mapState({
            orderData: state => state.order.orderData,
            shopData: state => state.order.shopData,
            userData: state => state.user.userData,
            isFetched: state => state.user.called.fetchWebPushTokens,
            web_push_tokens: state => state.user.web_push_tokens,
        }),

        isSubscribed(){
            return !!this.subscriptionStatus;
        },
        isSupportSW(){
            return this.permissionStatus != 'not_supported';
        },
        hasWebPushTokens() {
            return this.web_push_tokens && this.web_push_tokens.length > 0
        },
    },
    methods: {
        ...mapActions({
            actionFetchWebPushTokens: "user/fetchWebPushTokens",
        }),
        registerWebPushNotification(){
            if("serviceWorker" in navigator && "PushManager" in window && "Notification" in window){
                // ขอสิทธิ์จากผู้ใช้สำหรับการแสดง Notification
                Notification.requestPermission().then((permission) => {
                    if(permission === "granted"){
                        navigator.serviceWorker.register("/service-worker.js").then(async (registration) => {
                            try {
                                const vapidPublicKey = (await Axios.get(ApiUrl.lnwmsg("guest/vapid_public_key"))).data;
                                const convertedVapidKey = this.urlBase64ToUint8Array(vapidPublicKey)

                                const subscription = await registration.pushManager.subscribe({
                                    userVisibleOnly: true,
                                    applicationServerKey: convertedVapidKey
                                });

                                // ส่งข้อมูลการสมัครไปยัง backend เพื่อเอาไว้ยิง web push มาหา
                                await Axios.post(ApiUrl.lnwmsg("auth/add_token"), {
                                    platform: 'pwa_lnwme',
                                    role: 'any',
                                    device_token: JSON.stringify(subscription),
                                });
                            } catch(e) {
                                alert(`error: ${e}`);
                            }
                        }).catch(error => alert("Service Worker registration failed:" + error));
                    }else{
                        alert(this.$t('คุณยังไม่ได้อนุญาตให้แอพรับ Notification คุณสามารถเปิดได้ใน Settings ของมือถือ'));
                    }
                });
            }else{
                alert("Service Workers are not supported in this browser.");
            }

        },
        async unsubscribeWebPushNotification() {
            if ('serviceWorker' in navigator && "PushManager" in window) {
                try {
                    const registration = await navigator.serviceWorker.ready;
                    const subscription = await registration.pushManager.getSubscription();
                    if (subscription) {
                        // Unsubscribe the push subscription
                        await subscription.unsubscribe();

                        // Remove the subscription data from backend
                        await Axios.post(ApiUrl.lnwmsg("auth/remove_token"), {
                            device_token: JSON.stringify(subscription),
                        });

                        // Update the subscription status
                        this.subscriptionStatus = null;
                        alert('You have successfully unsubscribed from notifications.');
                    } else {
                        alert('You are not subscribed to notifications.');
                    }
                } catch (e) {
                    alert(`Failed to unsubscribe: ${e}`);
                }
            } else {
                alert("Service Workers are not supported in this browser.");
            }
        },
        // แปลง VAPID Key ให้อยู่ในรูปแบบ Uint8Array
        urlBase64ToUint8Array(base64String){
            const padding = "=".repeat((4 - base64String.length % 4) % 4);
            const base64 = (base64String + padding)
                .replace(/-/g, "+")
                .replace(/_/g, "/");
            const rawData = window.atob(base64);
            return Uint8Array.from([...rawData].map(char => char.charCodeAt(0)));
        },
        detectEnvironment() {
            const userAgent = navigator.userAgent;
            if (/android/i.test(userAgent)) {
                this.activeDevice.device = 'mobile';
                this.activeDevice.os = 'android';
                this.activeDevice.browser = /chrome/i.test(userAgent) ? 'chrome' : 'unknown';
            } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                this.activeDevice.device = 'mobile';
                this.activeDevice.os = 'ios';
                this.activeDevice.browser = /safari/i.test(userAgent) ? 'safari' : 'unknown';
            } else if (/Macintosh/.test(userAgent)) {
                this.activeDevice.device = 'desktop';
                this.activeDevice.os = 'macos';
                this.activeDevice.browser = /chrome/i.test(userAgent) ? 'chrome' : /safari/i.test(userAgent) ? 'safari' : 'unknown';
            } else if (/Windows/.test(userAgent)) {
                this.activeDevice.device = 'desktop';
                this.activeDevice.os = 'windows';
                this.activeDevice.browser = /chrome/i.test(userAgent) ? 'chrome' : 'unknown';
            }
        },
    },
    mounted(){
        if('serviceWorker' in navigator && "Notification" in window){
            navigator.serviceWorker.register("/service-worker.js").then((registration) => {
                this.permissionStatus = Notification.permission;

                registration.pushManager.getSubscription().then(subscription => {
                    this.subscriptionStatus = subscription;
                });

                // Check if there's an updated service worker waiting to be activated
                if(registration.waiting){
                    this.$emit('force-reload');
                }

                // Listen for updates to the service worker
                registration.onupdatefound = () => {
                    const newWorker = registration.installing;

                    newWorker.onstatechange = () => {
                        if(newWorker.state === 'installed' && navigator.serviceWorker.controller){
                            // New service worker is installed and waiting, emit event to parent
                            this.$emit('force-reload');
                        }
                    };
                };

            }).catch(error => {
                alert("Service Worker registration failed:" + error);
            });
        }else{
            this.permissionStatus = 'not_supported';
        }

        if (!this.isFetched){
            this.actionFetchWebPushTokens();
        }
        this.detectEnvironment();
    }
};
</script>