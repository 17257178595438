<template>
    <v-sheet>
        <component :is="currentComponent" />
    </v-sheet>
</template>

<script>
import HomePage from "@/views/HomePage";
import MyFavShops from "@/views/MyFavShops";
import MyShops from "@/views/MyShops";
import HighlightShops from "@/views/HighlightShops";
import NotiPage from "@/views/NotiPage";
import MixinsHelper from "@/components/Order/mixins/MixinsHelper";

export default {
    name: "AppPage",
    components: { HomePage, MyFavShops, MyShops, HighlightShops, NotiPage },
    mixins: [MixinsHelper],
    computed: {
        currentPage() {
            return this.$route.name;
        },
        currentComponent() {
            switch (this.currentPage) {
                case "home":
                    return "HomePage";
                case "favourite":
                    return "MyFavShops";
                case "my_shop":
                    return "MyShops";
                case "highlight":
                    return "HighlightShops";
                case "notifications":
                    return "NotiPage";
                default:
                    return null;
            }
        },
    },
    watch: {
        currentPage() {
            // Scroll to top when the page changes
            const ele = document.getElementById("lnwme-layout-content");
            if (ele) ele.scrollTop = 0;
        },
    },
};
</script>